.editor{
    margin: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 2rem);
}
.editor::-webkit-scrollbar {
    width: 7px;
}

.editor::-webkit-scrollbar-track {
    border-radius: 10px;
}
.editor::-webkit-scrollbar-thumb {
    background: #adb5bd;
    border-radius: 10px;
}
.editor::-webkit-scrollbar-thumb:hover {
    background: gray;
}

.medium-editor-toolbar{
    visibility: visible;
}
.ce-toolbar__plus{
    visibility: visible !important;
    display: block !important;
}
