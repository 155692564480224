

.pehla-beta{
    margin: 0.5rem;
    cursor: pointer;
    z-index: 333;
}
.beta{
  /*  display: none;*/
    margin-top:-38px ;
    z-index: -10;
    transition: .5s;
    width: 80%;
   /* display: flex;*/
    background-color: #fcfcff;
    border: #f0eff2 1.5px solid;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.pehla-beta:hover   .beta{
    display: block;
    margin-top:0px;


}

.beta:hover{
    display: block;
    margin-top:0px;

}

.menuButton{

}

.menuButton:hover{
    color: #d76d00;
    background-color: #fdf8f2;
}
