.left-side{
    vertical-align: center;
    font-family: "Work Sans","sans-serif";
}

.bgImage {
    background-image: url(/public/assets/images/Signinpagebg.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
}