/*  zebrapro workstation theme  start*/

/*@font-face {
    font-family: 'GorditaMedium';
    src: url("../../public/fonts/gordita-cufonfonts/Gordita Light.otf");
}*/

@font-face {
    font-family: 'MyFontName';
    src: url('../../public/fonts/gordita/AnyConv.com__Gordita Black.ttf')format('ttf');
}

* {
    font-family: 'MyFontName', sans-serif!important;
}

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 215, 109, 0 !important;
    --bs-secondary-rgb: 255, 253, 251 !important;
    --bs-success-rgb: 1, 102, 17 !important;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: work sans !important;
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: rgba(203, 203, 203, 1) !important;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #0d6efd;
    --bs-link-hover-color: #0a58ca;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd
}


/*   scrollbar - start   */


/* width */
*::-webkit-scrollbar {
    width: 7px;
}

/* Track */
*::-webkit-scrollbar-track {
    background: none;
}

/* Handle */
*::-webkit-scrollbar-thumb {
    background: #a1a1a1;
    border-radius: 10px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
    background: #767676;
}

/*   scrollbar - end   */


/*  card shadow  */
.card-shadow {
    box-shadow: 0px 4px 12px -4px rgb(145 145 145 / 10%);
}


.bg-light-orange {
    background-color: #FFFDFB !important;
}


.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #D76D00 !important;
    --bs-btn-border-color: #cd6800 !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #cd6800 !important;
    --bs-btn-hover-border-color: #cd6800 !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253 !important;
    --bs-btn-active-color: #fff !important;
    --bs-btn-active-bg: #cd6800 !important;
    --bs-btn-active-border-color: #b15a00 !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #D76D00 !important;
    --bs-btn-disabled-border-color: #D76D00 !important;
    --bs-btn-focus:#D76D00 !important;
}
.btn-check:focus+.btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #D76D00;
    border-color: #cd6800;
    box-shadow: 0 0 0 .25rem #ffc389;
}
/*.btn:focus-visible{
    box-shadow:
}*/

.btn-outline-primary {
    --bs-btn-color: #D76D00 !important;
    --bs-btn-border-color: #D76D00 !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: #d76d00 !important;
    --bs-btn-hover-border-color: #D76D00 !important;
    --bs-btn-focus-shadow-rgb: 13, 110, 253 !important;
    --bs-btn-active-color: #fff !important;
    --bs-btn-active-bg: #D76D00 !important;
    --bs-btn-active-border-color: #D76D00 !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: #D76D00 !important;
    --bs-btn-disabled-bg: transparent !important;
    --bs-btn-disabled-border-color: #D76D00 !important;
    --bs-gradient: none !important;
}

/* modals */
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: 2px solid #F0EFF2 !important;
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
}

.bg-none {
    background: none !important;
}


.cursor-pointer {
    cursor: pointer !important;
}

.logout-btn:hover .btn-hover-white {
    fill: white !important;
}


.outline-none {
    outline: none !important;
}

/*  zebrapro workstation theme  end*/

.activeOption > span {
    color: #D76D00 !important;
}

.activeOption {
    fill: #D76D00 !important;
    background-color: #fff8ec !important;
}

/*   dropdown select   */
.form-select:focus {
    border-color: #d76d0378 !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(215 109 3 / 22%) !important;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #d76d0378 !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(215 109 3 / 22%) !important;
}

.buttonOrange {
    border: 1px solid #d76d00 !important;
    color: #d76d00 !important;
    font-size: 0.8rem;
    text-align: center;
    cursor: pointer;
    transition-duration: 0.4s;
    background-color: white;
    padding: 0.5rem 1rem;

}

.buttonOrange:hover {
    color: white !important;
    background-color: #d76d00 !important;
}

.buttonOrangeFill {
    color: white !important;
    background-color: #d76d00 !important;
    font-size: 0.8rem;
    text-align: center;
    cursor: pointer;
    transition-duration: 0.4s;
    padding: 0.5rem 1rem;

}


.card-border {
    border: 2px solid rgba(240, 239, 242, 1);
}


/* font sizes */
.fs-7 {
    font-size: 12px !important;
}


/*  black colors variant   */
.bg-black-70 {
    color: rgba(103, 103, 103, 1) !important;

}


/*    My project bottom bar hover    */

.bottom-bar{
    position: relative;
    top: -45px;
    z-index: -1;
    transition: top 0.2s;
    height: 44px!important;
}



.bottom-bar-hover:hover > .bottom-bar{
    top: 0px!important;
    transition: top 0.2s;
    z-index: 2;
}

#menuBtn {
    background-color: white;
    color: black;
}

#menuBtn:hover {
    background-color: #ffedd2;
}

.activeBtn {
    background-color: #ffedd2 !important;
}

#noteListsDiv::-webkit-scrollbar {
    width: 2px !important;
}

#noteListsDiv::-webkit-scrollbar-thumb {
    background: #ffedd2;
    border-radius: 2px;
}

.dropdownButton {
    padding-left: 5px;
    padding-right: 5px;

}

.dropdownButton:hover .dropdown-item {
    background-color: #fdf8f2 !important;
    color: #d76d00 !important;
    border-radius: 5px !important;
}

.tabButton {
    background-color: #fffaf3;
    padding-bottom: 10px;
    padding-top: 10px;
    align-items: center;
}

.tabButton.active {
    background-color: #ffedd2 !important;
    color: #D76D00 !important;
}

/* for toggling menu triangle icon*/
.triangleToggle {
    transform: rotate(-90deg);
}