#imageCard {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;


}

#divBottom {
    min-width: 90%;

    background-color: #1a1a1a;
    border-color: #f0eff2;
    z-index: 1;
    position: absolute;
    transition: .2s;
    bottom: -35px;
    padding: 4px;
    border-radius: 10px;
}

#imageCard:hover > #divBottom {
    bottom: 10px;


}