:root {
    --ck-z-default: 100;
    --ck-z-modal: calc(var(--ck-z-default) + 999);
}

/*
 * Bring back the default CKEditor 5 input appearance by overriding
 * high–specificity styles brought by materialize.css.
 *
 * See: https://github.com/Dogfalo/materialize/blob/v1-dev/sass/components/forms/_input-fields.scss#L10-L40
 */
.ck input.ck-input.ck-input-text {
    box-shadow: none !important;;
    background: var(--ck-color-input-background);
    border: none !important;;
    padding: var(--ck-spacing-extra-tiny) var(--ck-spacing-medium);
    transition-property: box-shadow, border;
    transition: .2s ease-in-out;

    height: inherit;
    width: inherit;
    font-size: inherit;
    margin: 0;
    box-sizing: border-box;
}

.ck input.ck-input.ck-input-text:focus {
    border: none !important;;
    box-shadow: none !important;
}

.ck.ck-editor__top.ck-reset_all {
    border: none;
}

.ck-editor__main {
    box-shadow: none !important;;
    border: none;
}

.ck-editor__editable {
    box-shadow: none !important;
    border: none;
    height: calc(100vh - 2rem);
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {

    box-shadow: none !important;;
    border: none !important;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {

    box-shadow: none !important;;
    border: none !important;
}


.ck-editor__editable h1 {
    display: block !important;
    font-size: 2em !important;
    margin-top: 0.67em !important;
    margin-bottom: 0.67em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-weight: bold !important;
    width: 100%;
}

.ck-editor__editable h2 {
    display: block !important;
    font-size: 1.5em !important;
    margin-top: 0.83em !important;
    margin-bottom: 0.83em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-weight: bold !important;
}

.ck-editor__editable h3 {
    display: block !important;
    font-size: 1.17em !important;
    margin-top: 1em !important;
    margin-bottom: 1em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-weight: bold !important;
}

.ck-editor__editable h4 {
    display: block !important;
    margin-top: 1.33em !important;
    margin-bottom: 1.33em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-weight: bold !important;
}

.ck-editor__editable h5 {
    display: block !important;
    font-size: .83em !important;
    margin-top: 1.67em !important;
    margin-bottom: 1.67em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-weight: bold !important;
}

.ck-editor__editable h6 {
    display: block !important;
    font-size: .67em !important;
    margin-top: 2.33em !important;
    margin-bottom: 2.33em !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    font-weight: bold !important;
}

.ck-editor__editable p {
    -webkit-text-size-adjust: 100% !important;
    -webkit-font-smoothing: antialiased !important;
    font-size: 12px !important;
    color: #313649 !important;
    letter-spacing: .005em !important;
    -webkit-user-modify: read-write !important;
    overflow-wrap: break-word !important;
    -webkit-line-break: after-white-space !important;
    padding: .4em 0 !important;
    line-height: 1.6em !important;
    outline: none !important;
}

.ck-editor__editable ul {
    font-size: 12px !important;
    padding: 1rem;
}

.ck-editor__editable ol {
    font-size: 12px !important;
    padding: 1rem;
}

.ck-editor__editable .tc-row {
    font-size: 12px !important;
}

.ck-editor__nested-editable {
    height: auto !important;
}
