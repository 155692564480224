.heading{
    font-size: 20px;
    font-weight: 600;
}

.discription{
    font-size: 14px;
    font-weight: 500;
}

.inputFeild{
    height: 3.12rem;
    margin-right: 2rem;
    padding-left: 2rem;
}

.inputFeild::placeholder{
    color: #323232;
    font-size: 14px;
}

.btn-hover-icon-white:hover{
    fill: white
}
.btn-hover-icon-white:active{
    fill: white
}
